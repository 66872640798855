<template>
  <div class="box">
    <div class="top">
      <img class="logo" src="../assets/images/login_logo.png" alt="" />
    </div>
    <div class="bottom">
      <van-form @submit="loginBtn" :key="formKey">
        <van-field
          v-model="loginForm.phone"
          placeholder="输入手机号"
          :rules="[{ required: true, message: '' }]"
          type="tel"
        >
          <template #left-icon>
            <img class="description-icon" src="@/assets/images/u2.png" 
             alt="" >
          </template>
        </van-field>
        <van-field
          v-if="loginWay == 1"
          v-model="loginForm.code"
          placeholder="输入验证码"
          :rules="[{ required: true, message: '输入验证码' }]"
          :maxlength="6"
          type="digit"
        >
          <template #left-icon>
            <img class="description-icon" src="@/assets/images/icon_captch.png" 
             alt="" >
          </template>
          <template #button>
            <van-button
              v-if="!cutDownTime"
              size="small"
              native-type="button"
              @click.stop="sendCode"
              :disabled="!loginForm.phone"
              >发送验证码</van-button
            >
            <van-button
              v-if="cutDownTime"
              size="small"
              native-type="button"
              class="cut-dowm-time"
              disabled
              >重新发送({{ cutDownTime }})</van-button
            >
          </template>
        </van-field>
        <van-field
          v-else
          v-model="loginForm.pass_word"
          placeholder="输入密码"
          :rules="[{ required: true, message: '输入密码' }]"
          type="text"
          class="hidden"
        >
         <template #left-icon>
            <img class="description-icon" src="@/assets/images/icon_captch.png" 
             alt="" >
          </template>
      </van-field>
        <div class="submit-btn">
          <van-button
            round 
            block
            type="info"
            native-type="submit"
            class="can-submit"
            :disabled="!(loginForm.phone && (loginForm.code || loginForm.pass_word))"
            >登录</van-button
          >
          <div class="login-way" @click="toggleLoginType">切换{{ loginWay == 2 ? '验证码登录' : '密码登录' }}</div>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { sendCode, userLogin } from "@/api/login";
import { mapMutations } from 'vuex'
import { Toast } from 'vant'
import getShumei from '@/utils/web_sdk'
export default {
  name: "login",
  components: {},
  data() {
    return {
      Mid: '20241024135546f39d94a0de9fa18c365be45dd851960f019b8083243445d8', // 设备标识
      imgList: {
        userIcon: require("../assets/images/u2.png"),
        passwordIcon: require("../assets/images/icon_captch.png"),
      },
      loginForm: {},
      cutDownTime: 0,
      loginWay: 1, // 验证码登录-1，密码登录-2
      formKey: 0,
    };
  },
  mounted () {
    // const vm = this
    // vm.$nextTick(() => {
    //   getShumei().then(() => {
    //     vm.dealSmDeviceId(deviceId => {
    //       vm.Mid = deviceId
    //     });
    //   })
    // })
  },
  methods: {
    dealSmDeviceId(cb) {
      var smDeviceId = '';
      var smDeviceIdReady = false;
      window.SMSdk.ready(function () {
        if (window.SMSdk.getDeviceId) {
          smDeviceId = window.SMSdk.getDeviceId();
        }
        if (!smDeviceIdReady) {
          smDeviceIdReady = true;
          // 执行业务逻辑
          cb && cb(smDeviceId);
        }
      })
    },
    ...mapMutations(['userlogin']),
    sendCode() {
      this.cutDownTime = 60;
      let timer = setInterval(() => {
        this.cutDownTime--;
        if (this.cutDownTime == 0) {
          clearInterval(timer);
        }
      }, 1000);
      let params = {
        phone: this.loginForm.phone,
        platform: "web",
        send_type: 0,
        temp: 'SMS_TEMPLATE_LOGIN'
      };
      sendCode(params).then((res) => {
        if (res.code == 1) {
          Toast('验证码发送成功')
        } else {
          Toast(res.msg)
        }
      })
    },
    loginBtn() {
      let params = {
        login_type: this.loginWay == 1 ? "phone" : "pwd",
        platform: "web",
        ...this.loginForm,
      }
      userLogin(params, this.Mid).then((res) => {
        if (res.code == 1) {
          this.userlogin(res.data)
          this.$router.push({
            name: "homepage",
            params: {},
          })
        } else {
          Toast(res.msg)
        }
      })
    },
    // 切换登录方式
    toggleLoginType() {
      this.formKey++
      if (this.loginWay == 1) {
        delete this.loginForm.code
        this.loginWay = 2
      } else {
        delete this.loginForm.pass_word
        this.loginWay = 1
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.description-icon{
  width:18px;
  height:18px;
  margin-top:15px;
}
.box {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f6f7;
  .top {
    width: 100%;
    height: 200px;
    background-size: 100% 200px;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      display: block;
      margin-top: 67px;
      width: 150px;
      height: 150px;
    }
    .logo-name {
      margin-top: 11px;
      font-size: 20px;
      line-height: 20px;
      font-style: italic;
      font-weight: 700;
      color: #fb63b3;
    }
  }
  .bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 12px;
      font-family: Inter-Semi Bold, Inter;
      color: #fb63b3;
      line-height: 12px;
      letter-spacing: 2px;
      margin-top: 3px;
    }

    .van-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 59px;
    }

    .van-cell {
      width: 305px;
      height: 48px;
      background-color: #f5f6f7;
      border-bottom: 1px solid #e5e4e9;
    }

    :deep(.van-field__control) {
      line-height: 50px;
    }

    ::v-deep {
      .hidden .van-field__value {
        -webkit-text-security: disc;
      }
    }

    .van-button {
      padding: 8px;
      height: 32px;
      background-color: transparent;
      border: none;
      border-radius: 25px;
      color: #05BAE8 ;
    }

    .van-button.cut-dowm-time {
      color: #999;
    }

    .login-way {
      margin-top: 10px;
      font-size: 14px;
      color: #05BAE8;
    }

    .submit-btn {
      margin-top: 20px;
      .can-submit{
        background: linear-gradient( 90deg, #96F49E 22%, #A1FEE5 59%, #9AE9FD 76%, #CCD1FD 100%);
      }
      .van-button--info {
        width: 295px;
        height: 48px;
        border: none;
        color: #fff;

        &:disabled {
          background: #999;
        }
      }
    }
  }
}
</style>
